<!--<template>-->
<!--  <b-dropdown ref="add_new_team_member_dropdown" right class="w-full dropdown-menu-right hide_dropdown_caret  default_style_dropdown"-->
<!--              :no-caret="true">-->
<!--    <div :class="customClass" class="w-full dropdown_header bg-[#F4F6FA] !h-[56px] pr-3.5 rounded-[8px] d-flex align-items-center"-->
<!--         slot="button-content"-->
<!--         data-cy="select-camp">-->

<!--      <FloatingLabelInput-->
<!--        :id="id"-->
<!--        type="text"-->
<!--        v-model="model"-->
<!--        label="Member Role"-->
<!--        class="w-full !px-0"-->
<!--        :disabled="disabled"-->
<!--      >-->
<!--      </FloatingLabelInput>-->

<!--      <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>-->
<!--    </div>-->
<!--    <ul class="py-1">-->
<!--    <li-->
<!--      v-for="(option, index) in options"-->
<!--      :key="index"-->
<!--      :value="option.value"-->
<!--      v-model="model"-->
<!--      class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] text-[0.875rem] text-[#3C4549] flex justify-between font-poppins items-center"-->
<!--      @click="$refs.add_new_team_member_dropdown.visible = false"-->
<!--      @click.prevent="$refs.add_new_team_member_dropdown.hide(true)"-->
<!--    >-->
<!--      {{ option.label }}-->
<!--    </li>-->
<!--    </ul>-->
<!--  </b-dropdown>-->
<!--</template>-->



<template>
  <div class="w-full">
    <select
      :id="id"
      v-model="model"
      class="bg-gray-50 text-gray-900 text-sm rounded-[8px] block w-full p-2.5  !focus:border-none"
      :class="CustomClass"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :data-content="option.label"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>






<script>
import FloatingLabelInput from "@/ui/ui-kit/v2/FloatingLabelInput.vue";

export default {
  name: "Select-DropDown",
  components: {FloatingLabelInput},
  props: {
    options: {
      type: [Array,Object],
      required: true,
    },
    id: {
      value: String,
      default: "dropdownselect",
      required: true,
    },
    disabled:{
      value: Boolean,
      required:false,
    },
    value: {
      type: [String, Number, Boolean],
      default: 110,
      required: false,
    },
    showDefaultContent:{
      type: Boolean,
      default: false,
      required: false,
    },
    CustomClass:{
      type: String,
      default: false,
      required: false,
    }
  },
  data() {
    return {};
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
